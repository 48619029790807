import welcome from './asciiart.png'

const Home = (
    <div>
        <nav className="navbar navbar-expand-lg bg-dark" data-bs-theme="dark">
            <div className="container-fluid">
                <div className="navbar-brand">19481CC</div>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor01" aria-controls="navbarColor02" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
            </div>
        </nav>
        <body className="ms-4">
            <img className="mt-3" src={welcome} style={{ width: "80%", height: "80%" }}  alt="Logo" />
            <br />

            <figure className="mt-3">
                <blockquote className="blockquote">
                    <p className="mb-0">
                        You are not special. You're not a beautiful and unique snowflake.<br /> 
                        You're the same decaying organic matter as everything else. <br />
                        We're all part of the same compost heap. We're all singing, <br />
                        all dancing crap of the world.<br />
                    </p>
                </blockquote>
                <figcaption className="blockquote-footer">
                Chuck Palahniuk, Fight Club 
                </figcaption>
            </figure>

            <hr className="my-4" />

            <h4>About us</h4>
            <p> 
            Unlock the exciting world of technology with 19481 Computer Club. 
            We are a vibrant community of technology enthusiasts who share a passion for all things computing. 
            Whether you're a beginner or an expert, 
            our club offers a welcoming space for learning, networking, 
            and exploring the latest trends in the digital realm.
            </p>

            <h4>Knowledge Sharing</h4> 
            <p>
                Our club is built on the foundation of collaboration and knowledge sharing. 
                We believe that everyone has something valuable to contribute. 
                From coding and software development to hardware tinkering and cybersecurity, 
                our diverse membership offers a wealth of expertise to tap into.
            </p>

            <h4>Engaging Events</h4>
            <p>
                Get ready for a calendar packed with captivating events and activities. 
                We organize workshops, seminars, hackathons, and guest lectures by industry experts. 
                These events are designed to enhance your skills, broaden your horizons, 
                and foster meaningful connections with like-minded individuals.
            </p>

            <h4>Project Collaboration</h4>
            <p>
                Looking to work on exciting projects? Look no further! 
                We encourage our members to team up and embark on innovative projects together. 
                Whether it's building a mobile app, designing a website, or creating a gaming platform, 
                our club provides the perfect platform to unleash your creativity and make your ideas come to life.
            </p>

            <h4>Networking Opportunities</h4>
            <p>
                Connect with fellow tech enthusiasts, industry professionals, and potential employers. 
                Our club provides networking opportunities that can open doors to internships, job opportunities, 
                and mentorship. Expand your professional circle and build lasting relationships with individuals who share your passion for technology.
            </p>

            <h4>Fun and Community</h4>
            <p>
                At 19481 Computer Club, 
                we believe that learning and fun go hand in hand. Apart from technical activities, 
                we organize social events, game nights, and outings. 
                Join us and forge lasting friendships while having a great time exploring the world of technology.
            </p>

            <h4>Ready to dive in?</h4>
            <p>
                Whether you're a student, professional, or simply curious about the digital world, 1981 Computer Club  welcomes you with open arms. 
                Join us and become part of a dynamic community that will fuel your passion, expand your knowledge, and pave the way for an exciting future in technology.
                Sign up today to unlock the endless possibilities that await you! <br />
                <strong>conact@19481.io</strong>
            </p>


            <div>
                <hr className="my-4" />
                <a className="text-info" href="https://pastebin.com/t6B6fhcv" target="_blank">[1729, 4104, 13832, 20683, 32832, 39312, 40033, 46683, 64232] </a>
                <br />
                <br />
            </div>

        </body>
    </div>
)

export default Home